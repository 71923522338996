import React from 'react'

import Seo from '../components/seo'
import BaseLayout from '../components/layout/baseLayout'

const NotFoundPage = () => (
  <div className='bg-gray-900'>
    <Seo title='Not Found' />
    <BaseLayout>
      <div className='text-center text-xl mt-8 text-white'>
        This page does not exist
      </div>
      <div className='text-center mt-8 text-white'>
        This page is still under construction
      </div>
    </BaseLayout>
  </div>
)

export default NotFoundPage
